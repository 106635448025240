import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

const ContentFaceGuide = React.lazy(
  () => import("../src/components/modules/mobile/ContentFaceGuide")
);

const App = (): JSX.Element => {
  return (
    <div className=" antialiased">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ContentFaceGuide />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
